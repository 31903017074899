import './App.css';

import { ResponsiveHeader } from './App.js';
import { Footer } from './App.js';

import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

function Title() {
  return (
    <Helmet><title>Resources | SEA</title></Helmet>
  );
}

function ResourcesPage() {
  return (
    <>
      <div className="App">
        <Title />
        <ResponsiveHeader />
        <Main />
        <Footer />
      </div>
    </>
  );
}

function Main() {
  return (
    <div id='main-resources' className='main'>
      <div className='cont'>
        <img class='school' src='/school.jpg' alt='Seven Lakes High School'/>
        <div className='overlay'>
          <h1>Resources</h1>
        </div>
      </div>
      <FadeIn />
      <Form />
      <Form1 />
    </div>
  );
}

function Links() {
  return (
    <div className='links'>
      <div id='links-cont'>
        <a className='resource-link' href='https://www.remind.com/classes/engslhs/messages' target='_blank' rel="noreferrer">
          <div className='link'>
            <p>Remind Class</p>
          </div>
        </a>
        <a className='resource-link' href='https://docs.google.com/forms/d/e/1FAIpQLSfExrEFWtV6a_7DhCJ1_7ksbutER_ebeGsDK_L6Zv4QYSRDUA/viewform?usp=sf_link' target='_blank' rel="noreferrer">
          <div className='link'>
            <p>Registration Form</p>
          </div>
        </a>
        <a className='resource-link' href='https://docs.google.com/forms/d/e/1FAIpQLSf0U2XZPoKWUabmj3EEiA8RfwWe6uT2_eh-QJj-aNlX301-9g/viewform?usp=sf_link' target='_blank' rel="noreferrer">
          <div className='link'>
            <p>Officer Application</p>
          </div>
        </a>
        <a className='resource-link' href='https://docs.google.com/forms/d/e/1FAIpQLSe-M30Ac6dyBjG11UvP4zvd8O2_Ju9R51A1ghAGQ0YqYCsfBQ/viewform?usp=sf_link' target='_blank' rel="noreferrer">
          <div className='link'>
            <p>T-shirt Order Form</p>
          </div>
        </a>
        <a className='resource-link' href='https://docs.google.com/document/d/1ZJgj7Oa1eG09MofnpidCcAcuOL9AixJTHLpp9BdJcqw/edit?usp=sharing' target='_blank' rel="noreferrer">
          <div className='link'>
            <p>Plan of Operation</p>
          </div>
        </a>
        <div className='last-link'></div>
        <div className='last-link'></div>
      </div>
    </div>
  );
}

function FadeIn() {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setVisibility(true);
  }, []);

  return (
    <div className={`fade-in ${visibility ? 'show' : ''}`}>
      <Links />
    </div>
  );
}

function Form() {
  return (
    <div className='form'>
      <iframe 
        title='registration'
        id='form'
        src="https://docs.google.com/forms/d/e/1FAIpQLSfExrEFWtV6a_7DhCJ1_7ksbutER_ebeGsDK_L6Zv4QYSRDUA/viewform?embedded=true" 
        frameborder="0" >
        Loading…
      </iframe>
    </div>
  );
}

function Form1() {
  return (
    <div className='form'>
      <iframe 
        title='tshirt'
        id='form'
        src="https://docs.google.com/forms/d/e/1FAIpQLSe-M30Ac6dyBjG11UvP4zvd8O2_Ju9R51A1ghAGQ0YqYCsfBQ/viewform?embedded=true" 
        frameborder="0" >
        Loading…
      </iframe>
    </div>
  );
}

export default ResourcesPage;
