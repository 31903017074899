import React, { useState, useEffect } from 'react';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './home';
import ResourcesPage from './resources';
import CalendarPage from './calendar';
import ContactPage from './contact';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { Link } from 'react-router-dom';

function App() {
  return (
      <BrowserRouter>
          <Routes>
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route exact path="/" element={<HomePage />} />
            <Route element={<HomePage />} />
          </Routes>
      </BrowserRouter>
  );
};


export default App;

export function Header() {
  return (
    <div className='header'>
      <img className='logo' src='logo.png' alt='SEA logo'/>
      <nav>
        <ul>
          <li className='nav'><Link className='navlink' to='/'>Home</Link></li>
          <li className='nav'><Link className='navlink' to='/resources'>Resources</Link></li>
          <li className='nav'><Link className='navlink' to='/calendar'>Calendar</Link></li>
          <li className='nav'><Link className='navlink' to='/contact'>Contact Us</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export function MobileHeader() {
  return (
    <>
      <MobileMenu />
    </>
  );
};

export function ResponsiveHeader() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 708)
    };

    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <div>
      {isMobile ? <MobileHeader /> : <Header />}
    </div>
  );
};

export function MobileMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div>
      <div className='header'>
        <div className='spacer'></div>
        <img className='logo' src='logo.png' alt='SEA logo'/>
        <i className='fa-solid fa-bars' onClick={toggleMenu}></i>
      </div>
      {isMenuOpen && (
        <div className='menu'>
          <ul>
            <Link className='navlink' to='/'><li className='nav nav-mobile'>Home</li></Link>
            <Link className='navlink' to='/resources'><li className='nav nav-mobile'>Resources</li></Link>
            <Link className='navlink' to='/calendar'><li className='nav nav-mobile'>Calendar</li></Link>
            <Link className='navlink' to='/contact'><li className='nav nav-mobile'>Contact Us</li></Link>
          </ul>
        </div>
      )}
    </div>
  );
};

export function Footer() {
  return (
    <div className='footer'>
      <div id="orange"></div>
      <div id='celadon'></div>
      <div id='blue'>
        <a href='#top'><i class="fa-solid fa-angle-up"></i></a>
      </div>
    </div>
  );
};
