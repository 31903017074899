import React from 'react';
import './App.css';

import { ResponsiveHeader } from './App.js';
import { Footer } from './App.js';

import { Helmet } from 'react-helmet';

function Title() {
  return (
    <Helmet><title>Contact Us | SEA</title></Helmet>
  );
}

function ContactPage() {
  return (
    <>
      <div className="App">
        <Title />
        <ResponsiveHeader />
        <Main />
        <Footer />
      </div>
    </>
  );
}

function Main() {
  return (
    <div className='main'>
      <div className='cont'>
        <img className='school' src='/school.jpg' alt='Seven Lakes High School'/>
        <div className='overlay'>
          <h1>Contact Us</h1>
        </div>
      </div>
      <ContactInfo />
      <Form />
    </div>
  );
}

function ContactInfo() {
  return (
    <>
      <div id='contact-info'>
          <div id='email'>
            <a href='mailto:contact@slhsengineering.org'><i className="contact fas fa-envelope"></i></a>
            <p>Email</p>
          </div>
          <div id='remind'>
            <a href='https://www.remind.com/classes/engslhs/messages/f02ff147-2aaf-443d-be5c-0a04c33c2645' target='_blank' rel="noreferrer"><i className="contact fas fa-comment"></i></a>
            <p>Remind</p>
            
          </div>
          <div id='instagram'>
          <a href='https://www.instagram.com/slhsengineering/' target='_blank' rel="noreferrer"><i className="contact fa-brands fa-instagram"></i></a>
            <p>Instagram</p>
          </div>
      </div>
    </>
  );
}

function Form() {
  return (
    <div className='form'>
      <iframe 
        title='contact'
        id='form'
        src="https://docs.google.com/forms/d/e/1FAIpQLScCdJRY3x1odPxPIJCJuY7-18_-HMsjv7PaIff9cUKZrOr_Xg/viewform?embedded=true" 
        frameborder="0" >
        Loading…
      </iframe>
    </div>
  );
}

export default ContactPage;
