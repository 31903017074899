import React, { useState, useEffect } from 'react';
import './App.css';

import { ResponsiveHeader } from './App.js';
import { Footer } from './App.js';

import { Helmet } from 'react-helmet';

function Title() {
  return (
    <Helmet><title>Calendar | SEA</title></Helmet>
  );
}

function CalendarPage() {
  return (
    <>
      <div className="App">
        <Title />
        <ResponsiveHeader />
        <Main />
        <Footer />
      </div>
    </>
  );
}

function Main() {
  return (
    <div id='main-calendar'>
      <div className='cont'>
        <img className='school' src='/school.jpg' alt='Seven Lakes High School'/>
        <div className='overlay'>
          <h1>Calendar</h1>
        </div>
      </div>
      <ResponsiveCalendar />
    </div>
  );
}

function Calendar() {
  return (
    <div id='calendar'>
      <iframe 
        title='calendar'
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&showPrint=0&showTabs=1&showTitle=0&showTz=0&showCalendars=0&showDate=1&title=SEA%20Master%20Calendar&src=Y29udGFjdEBzbGhzZW5naW5lZXJpbmcub3Jn&color=%23039BE5"   
        frameborder="0" 
        scrolling="no">
      </iframe>
    </div>
  );
}

function ResponsiveCalendar() {
  const [mobileCalendar, setMobileCalendar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobileCalendar(window.innerWidth < 600)
    };

    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <div>
      {mobileCalendar ? <MobileCalendar /> : <Calendar />}
    </div>
  );
}

function MobileCalendar() {
  return (
    <a className='resource-link' href='https://calendar.google.com/calendar/embed?src=contact%40slhsengineering.org&ctz=America%2FChicago' target='_blank' rel='noreferrer'><div className='link' id='calendar-link'><p>Click to view our calendar!</p></div></a>
  )
}

export default CalendarPage;
