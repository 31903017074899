import React from 'react';
import './App.css';

import { ResponsiveHeader } from './App.js';
import { Footer } from './App.js';

import { Helmet } from 'react-helmet';

function Title() {
  return (
    <Helmet><title>SEA | Katy ISD</title></Helmet>
  );
}

function HomePage() {
  return (
      <div className="App">
        <Title />
        <ResponsiveHeader />
        <Main />
        <Footer />
      </div>
  );
}

function Main() {
  return (
    <div className='main'>
      <div id='cont-home'>
        <img className='school' src='/school.jpg' alt='Seven Lakes High School'/>
        <div id='overlay-home'>
          <h1>Spartan Engineering Association</h1>
          <p className='subtitle'>Seven Lakes High School</p>
        </div>
      </div>
      <Welcome />
      <Announcement />
      <Gallery />
      <Call />
    </div>
  );
}

function Welcome() {
  return (
      <div className='welcome-post'>
      <h2>Welcome!</h2>
        <p className='desc'>Welcome to the official website of the Spartan Engineering Association! As a student organization at Seven Lakes High School, our goal is to engage students
          in the diverse and rewarding field of engineering. Through active learning and community involvement, we strive to instill passion, creativity, and problem-solving skills
           in our members, empowering them to consider engineering as a potential career path.
        </p>
        <p className='desc'>This website serves as the primary place for information regarding membership, activities, contact info, and more. If you're looking for something about our club,
        you'll find it here!
        </p>
    </div>
  );
}

function Announcement() {
  return (
    <>
      <h3>Club T-shirt</h3>
      <div className='announcement'> 
      <img src='Screenshot 2023-10-15 165535.png' alt='T-shirt front' />
      <img src='Screenshot 2023-10-15 165550.png' alt='T-shirt back' />
        <p>The design for the 2023-2024 club T-shirt has been finalized! Support our organization by purchasing a shirt through the order form found on the Resources page.</p>
      </div>
    </>
  )
}

function Gallery() {
  return (
    <>
      <hr />
      <div className="gallery">
        <div className='gallery-cont'>
          <img className='gallery-img' src='20230905_201656955_iOS.jpg' alt='Students constructing a bridge'/>
        </div>
        <div className='gallery-cont'>
          <img className='gallery-img' src='20230905_205554646_iOS.jpg' alt='Bridge strength test'/>
        </div>
      </div>
      <p className='gallery-caption'>Thank you to everyone who attended our first meeting!</p>
    </>
  );
}

function Call() {
  return (
    <div className='call'>
      <hr />
      <h3>Become a Member</h3>
      <p>SEA members get to participate in fun activities, learn fundamental engineering practices, hear from professional guest speakers, and be exposed to volunteering and
        research opportunities.
      </p>
      <p>Becoming a member of the SEA is easy! All you need to do is join our Remind class and complete our registration form.</p>
      <h4>Remind class code: @engslhs</h4>
      <h4>The registration form can be found on the Resources page.</h4>
      <div id='empty'></div>
    </div>
  );
}

export default HomePage;
